import { create } from "zustand";
import produce from "immer";

const water = "#5EBFB5";
const colorMap = [
    "#FCE0AE",
    "#F2B591",
    "#A7A267",
    "#656347",
    "#9AA7AD",
    "#EC0000", // adding for ktab
    "#ecca00", // adding for ktab
    "#0047ab"  // adding for ktab
];

export const mapState = create((mState) => ({
    colors: {
        Water: {
            value: 0.21,
            color: "#00a9ff"
        },
        Shore: {
            value: 0.01,
            color: "#ffd68f"
        },
        Beach: {
            value: 0.04,
            color: "#efb28f"
        },
        Shrub: {
            value: 0.1,
            color: "#9ea667"
        },
        Forest: {
            value: 0.29,
            color: "#586647"
        },
        Stone: {
            value: 0.36,
            color: "#656565"
        },
        Snow: {
            value: 0.6,
            color: "#9aa7ad"
        }
    },

    generation: {
        Seed: Math.random(),
        Height: 1,
        Scale: 0.2,
        Detail: 0.5,
        Fuzzyness: 0.25
        // Contrast: 0.5,
    },

    general: {
        Trees: false,
        Grass: false,
        Clouds: false
    },
}));

