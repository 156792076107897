import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { Animated, Linking, PanResponder, Platform, TouchableOpacity, View } from "react-native";
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { Dimensions, StyleSheet } from "react-native";
import { useSelector } from "react-redux";
import { getAuth, signOut } from "firebase/auth";
import { setLocalStorage } from "../utils/LocalStorage";
import LoLUser from "./svgs/LoLUser";
import { getLiveStreamUrl } from "../utils/LiveStream";
import { interpolate, useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";

const { width, height } = Dimensions.get('window');
let setOnPress = true;
let touchSize = 100;
const AssitiveTouch = ({ button, children, navigation, screen }) => {
  const [touchIcon, setTouchIcon] = useState("login-variant");
  const [colorTouchIcon, setColorTouchIcon] = useState("white");
 
  let view_height = height;
  let view_width = width;
  let view_start_y = 0;
  let view_start_x = 0;
  let view_end_x = width;
  let view_end_y = height;
  // Vi tri kiem soat la goc tren trai
  let current_position_x = width - touchSize;
  let current_position_y = 0;

  const pan = useRef(new Animated.ValueXY()).current;
  const handleShouldSetPanResponder = (evt, gestureState) => {
    return evt.nativeEvent.touches.length === 1 && Math.abs(gestureState.dy) > 5;
  };

  const onLongPress = async () => {
    console.log("setOnPress: ", setOnPress);
    if (setOnPress) {
      if (navigation) {
        console.log("longPress");
        if (uid != "") {
        } else {
          await setLocalStorage("screen", screen);
        }
      }
    }
    setOnPress = true;
  }
  const onMoveEnd = () => {
    //console.log("onMoveEnd function");
  }

  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: handleShouldSetPanResponder,
      onMoveShouldSetPanResponder: handleShouldSetPanResponder,
      onPanResponderGrant: (evt, gestureState) => {
        pan.setOffset({
          x: pan.x._value,
          y: pan.y._value
        });
      },
      onPanResponderMove: (evt, gestureState) => {
        setOnPress = false;
        // console.log("onPanResponderMove: setOnPress = ", setOnPress);
        let dx = gestureState.moveX - gestureState.x0;
        let dy = gestureState.moveY - gestureState.y0;

        if (current_position_x + dx < 0) dx = - current_position_x;
        if (current_position_x + dx + touchSize > width) dx = width - touchSize - current_position_x;
        if (current_position_y + dy < 0) dy = - current_position_y;
        if (current_position_y + dy + touchSize > height) dy = height - touchSize - current_position_y;

        let tempGesture = { x0: gestureState.x0, moveX: gestureState.x0 + dx, y0: gestureState.y0, moveY: gestureState.y0 + dy, dx: dx, dy: dy };

        Animated.event(
          [
            null,
            { dx: pan.x, dy: pan.y }
          ],
          { useNativeDriver: false }
        )(evt, tempGesture);
      },
      onPanResponderRelease: (evt, gestureState) => {
        let dx = gestureState.moveX - gestureState.x0;
        let dy = gestureState.moveY - gestureState.y0;

        if (current_position_x + dx < 0) dx = - current_position_x;
        if (current_position_x + dx + touchSize > width) dx = width - touchSize - current_position_x;
        if (current_position_y + dy < 0) dy = - current_position_y;
        if (current_position_y + dy + touchSize > height) dy = height - touchSize - current_position_y;

        current_position_x = current_position_x + dx;
        current_position_y = current_position_y + dy;

        pan.flattenOffset();
      }
    })
  ).current;

  return (
    <View
      style={[styles.container, { width: width, height: height }]}
    // onLayout={
    //   (e) => {
    //     view_height = e.nativeEvent.layout.height;
    //     view_width = e.nativeEvent.layout.width;
    //     view_start_x = e.nativeEvent.layout.x;
    //     view_start_y = e.nativeEvent.layout.y;
    //     view_end_x = view_start_x + view_width;
    //     view_end_y = view_start_y + view_height;
    //     current_position_x = view_end_x - (FAB_WIDTH / 2);
    //     current_position_y = ((view_height / 2) + view_start_y);
    //     console.log("onLayout function: view_width = ", view_width, ", view_height = ", view_height);
    //   }
    // }
    >
      {children}
      <Animated.View
        style={{
          transform: [{ translateX: pan.x }, { translateY: pan.y }],
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: 1
        }}
        {...panResponder.panHandlers}
      >
        <View style={[styles.box, { height: touchSize, width: touchSize }]}>
          {button || <AssetiveTouchButton onLongPress={onLongPress} size={touchSize} touchIcon={touchIcon} />}
        </View>
      </Animated.View>
    </View>
  );
}

const AssetiveTouchButton = ({ onLongPress, size, touchIcon}) => {
  const [spin, setSpin] = useState(0);
  let live = getLiveStreamUrl();
  // console.log("live: ", live, ", uid: ", uid);
  useEffect(()=>{
    let interval = setInterval(()=>{
      setSpin((spin + 1)%5);
    }, 1000);
    return ()=>{
      clearInterval(interval);
    }
  },[spin]);
  const onPress = async () => {
    if (setOnPress) {
      if(live != ""){
        if(Platform.OS == 'web'){
          window.open(live, '_blank');
       } else {
          Linking.openURL(live);
       }
      }
    }
    setOnPress = true;
  }
  let containerStyleSize = { height: size - 10, width: size - 10, borderRadius: (size - 10) / 2 };
  let thirdLayerSize = { height: size - 20, width: size - 20, borderRadius: (size - 20) / 2, backgroundColor: "white" };
  return (
    <TouchableOpacity
      onPress={() => onPress()}
      onLongPress={() => onLongPress ? onLongPress() : null}
      activeOpacity={1}
      style={[styles.buttonContainer, containerStyleSize, { opacity: 0.65 }]}>
      <View style={[styles.thirdLayer, thirdLayerSize]}>
        {
            <>
              {
                (spin % 5) == 0 ?
                  <>
                    <LoLUser></LoLUser>
                  </>
                  :
                  <>
                    <Icon
                      name={"broadcast"}
                      size={size - 30}
                      borderRadius={size - 30}
                      color={"red"}
                      iconStyle={{
                        marginTop: 0,
                        marginBottom: 0,
                        paddingTop: 0,
                        paddingBottom: 0,
                        marginRight: 0
                      }}
                    />
                  </>
              }
            </>
        }

      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  box: {
    zIndex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonContainer: {
    width: 60,
    height: 60,
    borderRadius: 30,
    backgroundColor: 'gray',
    justifyContent: 'center',
    alignItems: 'center',
  },
  thirdLayer: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: 'rgba(255,255,255, 0.4)',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default AssitiveTouch;