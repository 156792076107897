import { useEffect, useLayoutEffect, useState } from "react";
import { Dimensions, Image, Platform, TextInput } from "react-native";
import { View } from "react-native";
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import * as WebBrowser from 'expo-web-browser';
import firebase from 'firebase/compat/app';
import { genMaxSlide4Active, genSlide4Active, getSlideData, getUrlFromStorage } from "../utils/Tool";
import { firebaseConfig } from "../../config";
import { Audio } from "expo-av";
import * as Linking from 'expo-linking';
import { useSelector } from "react-redux";
import AssitiveTouch from "../components/AssitiveTouch";
import { screens } from "../utils/ScreenUtils";


try {
    firebase.initializeApp(firebaseConfig);
} catch (error) {
    console.log("Initializing error ", error);
}

WebBrowser.maybeCompleteAuthSession();

const soundObject = new Audio.Sound();

function speech(text) {
    if (text == undefined || text.trim() == "") {
        return;
    }
    text = text.trim();
    if ('speechSynthesis' in window) {
        function textToSpeech(text, voices) {
            let speech = new SpeechSynthesisUtterance();
            voices = voices.filter(voice => voice.lang === "vi-VN");
            if (voices.length > 0) {
                // console.log(voices);
                speech.lang = 'vi-VN';
                speech.voice = voices[0];
            }
            speech.text = text;
            // console.log(speech);
            speechSynthesis.speak(speech);
        }

        // textToSpeech(text);
        function setSpeech() {
            return new Promise(
                function (resolve, reject) {
                    let synth = speechSynthesis;
                    let id;
                    id = setInterval(() => {
                        if (synth.getVoices().length !== 0) {
                            resolve(synth.getVoices());
                        } else {
                            resolve([]);
                        }
                        clearInterval(id);
                    }, 10);
                }
            )
        }
        let s = setSpeech();
        s.then((voices) => textToSpeech(text, voices));
    }
}

var background_url = "https://firebasestorage.googleapis.com/v0/b/ktab-extension.appspot.com/o/uploads%2F8674f192789aafa096233bbf61f134f7.jpg?alt=media&token=bfe0f8a9-608c-45bd-81e9-2c0fe9b51825";
var activeGroup = "3";
export var loadColor = "#ee5c09";
export var mainColor = "#099bee";
export var bgColor = "#ffffff";

const NewsScreen = ({ navigation }) => {
    const [background, setBackground] = useState({ uri: background_url });
    const [slide, setSlide] = useState(null);
    const [slideId, setSlideId] = useState('-1');
    const [caption, setCaption] = useState('XGame');
    const [longCaption, setLongCaption] = useState('XGame - Esport');
    const [url, setUrl] = useState('');
    const [dimension, setDimension] = useState(null);
    const [showLongCaption, setShowLongCaption] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);
    const [isNext, setIsNext] = useState(false);
    const [isPrev, setIsPrev] = useState(false);
    const [actionLogout, setActionLogout] = useState(false);
    const { uid } = useSelector(state => state.firebaseReducers);

    useEffect(() => {
        const subscription = Dimensions.addEventListener(
            'change',
            ({ window }) => {
                if (dimension == null) {
                    setDimension(window);
                } else {
                    let pre = dimension.width >= dimension.height;
                    let now = window.width >= window.height;
                    if (pre != now) {
                        setDimension(window);
                    }
                }
            },
        );
        if (dimension == null) {
            setDimension(Dimensions.get('window'));
        }
        return () => subscription?.remove();
    }, [dimension]);

    useEffect(() => {
        const enableSound = async () => {
            if (Platform.OS === "ios") {
                await Audio.setAudioModeAsync({
                    playsInSilentModeIOS: true,
                });
                await soundObject.loadAsync(require("../../data/soundFile.mp3"));
                await soundObject.playAsync();
            }
        }

        enableSound();
    }, []);

    useLayoutEffect(() => {
        if (slideId == '-1') {
            refreshSlide();
        }
    }, []);

    const prevSlideAction = async () => {
        setIsPrev(true);
        if (slide == null) {
            setIsPrev(false);
            return;
        }
        if (slide.prev != slideId) {
            setSlideId(slide.prev);
            updateScreen(slide.prev);
        }
        setIsPrev(false);
    };

    const updateScreen = async (id) => {
        if (id == '-1') {
            return;
        }
        let slideDB = await getSlideData(id);
        if (slideDB?.img) {
            setBackground({ uri: slideDB.img });
        } else {
            if (slideDB?.storage) {
                let uri_image = await getUrlFromStorage(slideDB?.storage);
                setBackground({ uri: uri_image });
            } else {
                let uri_image = await getUrl4Slide(id);
                setBackground({ uri: uri_image });
            }
        }
        if (slideDB?.url) {
            setUrl(slideDB.url);
        } else {
            setUrl('');
        }
        setSlide(slideDB);
        setCaption(slideDB.caption);
        setLongCaption(slideDB.longCaption);
    };

    const refreshSlide = async () => {
        setIsRefresh(true);
        let id = await genSlide4Active(activeGroup);
        if (id != slideId) {
            updateScreen(id);
            setSlideId(id);
        }
        setIsRefresh(false);
    };

    const nextSlideAction = async () => {
        setIsNext(true);
        if (slide.next != slideId) {
            setSlideId(slide.next);
            updateScreen(slide.next);
        }
        setIsNext(false);
    };

    const getLastSlideActive = async () => {
        setIsRefresh(true);
        let id = await genMaxSlide4Active(activeGroup);
        if (id != slideId) {
            updateScreen(id);
            setSlideId(id);
        }
        setIsRefresh(false);
    }

    const speechLongCaption = async (text) => {
        if (text != null) {
            //Speech.speak(text);
            speech(text);
        }
    }

    const gotoKTab = () => {
        Linking.openURL("https://ktab-extension.web.app/");
    }

    const gotoHomeScreen = () => {
        navigation.navigate("Home");
    }

    let global_view_style = { width: "100%", height: "100%", flexDirection: "column", backgroundColor: bgColor, justifyContent: 'center' };
    let actionSlideStyle = { flexDirection: "column", position: 'absolute', alignItems: "flex-end", right: 5 };
    let actionOpeStyle = { flexDirection: "column", position: 'absolute', alignItems: "flex-start", left: 5 };
    if (dimension != null && dimension.width < dimension.height) {
        actionSlideStyle = { flexDirection: "row", justifyContent: 'center', opacity: 0.5, right: 5, left: 5, position: 'absolute' };
        actionOpeStyle = { flexDirection: "row", position: 'absolute', alignItems: "center", right: 5, left: 5, top: 20 };
    }
    return (
        <AssitiveTouch navigation={navigation} screen={screens.news}>
            <View style={global_view_style}>
                <View style={{ flex: 1, width: "100%", flexDirection: "column " }}>
                    <Image
                        style={{ width: "100%", height: "100%", resizeMode: "stretch" }}
                        source={background}
                    ></Image>
                </View>
                {showLongCaption ?
                    <>
                        <View style={{
                            flexDirection: "row", position: 'absolute', elevation: 3, bottom: 0,
                            backgroundColor: mainColor, opacity: 0.45, width: "100%", fontFamily: 'monospace'
                        }}>
                            <TextInput
                                style={{ width: '100%', borderWidth: 0, color: "#ffffff", fontSize: 21 }}
                                value={longCaption}
                                multiline
                                readOnly={true}
                                numberOfLines={9}
                            />
                        </View>
                    </>
                    :
                    null
                }
                <View style={actionSlideStyle} >
                    <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
                        <Icon.Button
                            name="chevron-left"
                            backgroundColor={isPrev ? loadColor : mainColor}
                            onPress={prevSlideAction}
                            size={32}
                            style={{ width: 60 }}
                        />
                    </View>
                    <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
                        <Icon.Button
                            name="refresh"
                            backgroundColor={isRefresh ? loadColor : mainColor}
                            onPress={refreshSlide}
                            size={32}
                            style={{ width: 60 }}
                        />
                    </View>
                    <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
                        <Icon.Button
                            name="chevron-right"
                            backgroundColor={isNext ? loadColor : mainColor}
                            onPress={nextSlideAction}
                            size={32}
                            style={{ width: 60 }}
                        />
                    </View>
                    {activeGroup != -1 ?
                        <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
                            <Icon.Button
                                name="chevron-double-right"
                                backgroundColor={isNext ? loadColor : mainColor}
                                onPress={getLastSlideActive}
                                size={32}
                                style={{ width: 60 }}
                            />
                        </View>
                        :
                        null
                    }
                </View>
                <View style={actionOpeStyle} >
                    {!actionLogout ?
                        <>
                            {(dimension != null && dimension.width < dimension.height) ?
                                <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
                                    <Icon.Button
                                        name="account-voice"
                                        backgroundColor={mainColor}
                                        onPress={() => speechLongCaption(longCaption)}
                                        size={32}
                                        style={{ width: 60 }}
                                    />
                                </View>
                                :
                                null
                            }
                            <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
                                <Icon.Button
                                    name="home"
                                    backgroundColor={mainColor}
                                    onPress={gotoHomeScreen}
                                    size={32}
                                />
                            </View>
                            <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
                                <Icon.Button
                                    name="cast-education"
                                    backgroundColor={mainColor}
                                    onPress={gotoKTab}
                                    size={32}
                                />
                            </View>
                            {!(dimension != null && dimension.width < dimension.height) ?
                                <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
                                    <Icon.Button
                                        name="account-voice"
                                        backgroundColor={mainColor}
                                        onPress={() => speechLongCaption(longCaption)}
                                        size={32}
                                        style={{ width: 60 }}
                                    />
                                </View>
                                :
                                null
                            }
                        </>
                        :
                        <>
                        </>
                    }

                </View>
                <View style={{ width: "100%", flexDirection: "column", bottom: 0 }}>
                    <View style={{ width: "100%", flexDirection: "row" }}>
                        <View style={{ flex: 9, fontFamily: 'monospace', bottom: 0 }}>
                            {showLongCaption ?
                                <>
                                    <Icon.Button
                                        name="chevron-down-circle-outline"
                                        backgroundColor={mainColor}
                                        onPress={() => setShowLongCaption(!showLongCaption)}
                                        size={32}
                                    >
                                        {caption}
                                    </Icon.Button>
                                </>
                                :
                                <>
                                    <Icon.Button
                                        name="chevron-up-circle-outline"
                                        backgroundColor={mainColor}
                                        onPress={() => setShowLongCaption(!showLongCaption)}
                                        size={32}
                                    >
                                        {caption}
                                    </Icon.Button>
                                </>
                            }

                        </View>
                        {url ?
                            <View style={{ flex: 1, minWidth: 60, bottom: 0, justifyContent: 'flex-end' }}>
                                <Icon.Button
                                    name="open-in-new"
                                    backgroundColor="yellow"
                                    color={mainColor}
                                    onPress={() => Linking.openURL(url)}
                                    size={32}
                                >
                                </Icon.Button>
                            </View>
                            :
                            null
                        }
                    </View>
                </View>
            </View>
        </AssitiveTouch>
    );
}

export default NewsScreen;