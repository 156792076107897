import { useRef} from "react";
import Roboto from "./fonts/Roboto_Regular.json";
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import {TextGeometry} from 'three/examples/jsm/geometries/TextGeometry.js' ;
import {extend} from "@react-three/fiber";

extend({TextGeometry});

const HistoryText=(props)=>{
    const mesh = useRef();
    const {position} = props;
    const font = new FontLoader().parse(Roboto);
    let userName ="2024";
    const textNameOptions = {
        font,
        size: 4,
        height: 2.5
    };
    let shiftUserName = ((userName.length + 1.0)/2.0)*(-0.1);
    
    return(
        <group
            position={position? position: [0,0,0]}
            scale={3.0}
            ref={mesh}
        >
            <mesh
                position={[shiftUserName,0,1.2]}
                scale={0.1}
                rotation-x={Math.PI/2}
            >
                <textGeometry args={[userName, textNameOptions]} />
                <meshStandardMaterial attach='material' color="yellow" />
            </mesh>
        </group>
    );
}
export default HistoryText;