import Svg, { Path, G, Circle} from "react-native-svg"

const LoLUser = ({ width, height }) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 4000 4000" preserveAspectRatio="xMidYMid meet">
            <G fill="#000000" stroke="none" transform="translate(500, 650)">
                <Path fill="blue" d="M1530 2844 l1 -239 206 -118 c113 -65 207 -116 210 -114 2 3 -18 31 -45 63 -124 150 -243 348 -318 531 -23 58 -45 107 -48 110 -3 4 -6 -101 -6 -233z" />
                <Path fill="orange" d="M595 2531 c-100 -46 -142 -159 -95 -257 17 -34 82 -92 326 -294 167 -138 316 -255 330 -260 30 -12 274 -14 274 -2 -1 4 -68 45 -150 92 l-150 85 0 80 -1 80 -162 224 c-136 188 -170 228 -206 248 -53 28 -111 29 -166 4z" />
                <Path fill="orange" d="M2315 2532 c-51 -24 -47 -19 -225 -267 l-150 -208 0 -81 -1 -81 -149 -85 c-82 -47 -150 -88 -150 -92 0 -10 235 -9 268 1 13 3 160 119 327 256 210 173 311 262 326 290 32 56 32 136 0 188 -51 83 -163 118 -246 79z" />
                <Path fill="red" d="M1367 2422 l-167 -97 0 -191 0 -192 167 -96 166 -97 151 87 c83 48 159 94 169 102 16 14 17 34 15 201 l-3 186 -150 88 c-82 48 -157 92 -165 96 -11 7 -63 -18 -183 -87z m159 -539 c-6 -6 -186 86 -186 95 0 4 42 34 93 68 l92 61 3 -110 c1 -60 1 -112 -2 -114z" />
                <Path fill="blue" d="M2220 1865 c-124 -102 -242 -195 -262 -206 -33 -17 -59 -19 -236 -19 l-199 0 -38 -55 c-57 -83 -176 -189 -253 -227 -37 -17 -69 -33 -71 -34 -2 -2 9 -45 23 -96 54 -186 132 -304 218 -330 34 -10 48 -9 84 4 l44 16 0 -129 c0 -144 -2 -140 71 -118 88 27 131 91 234 344 31 77 79 186 107 242 l50 102 -62 78 -62 78 34 6 c18 4 99 15 181 25 81 10 147 22 147 27 0 5 -10 25 -21 44 -18 29 -20 37 -8 47 8 8 104 91 214 186 110 96 202 177 203 182 2 4 -14 8 -35 8 -21 0 -60 3 -88 6 l-50 6 -225 -187z m-542 -370 c26 -18 87 -53 134 -75 83 -40 86 -43 76 -65 -10 -23 -12 -24 -42 -8 -51 26 -148 98 -191 142 -48 50 -41 52 23 6z m-103 -147 c15 -7 25 -20 25 -32 1 -27 35 -102 65 -142 16 -20 27 -52 31 -86 l6 -53 -169 0 -169 0 7 51 c4 33 19 70 42 105 20 30 41 76 48 102 15 63 55 82 114 55z" />
                <Path fill="red" d="M422 1881 c-173 -261 -290 -588 -336 -941 -16 -124 -36 -410 -36 -527 l0 -73 203 0 202 -1 84 -149 c47 -83 88 -150 91 -150 4 0 66 68 139 150 95 108 138 150 154 150 16 0 38 -22 80 -78 31 -42 76 -98 100 -125 l42 -47 75 59 75 59 40 -21 c122 -62 284 -61 391 2 l36 21 66 -51 c37 -29 72 -56 78 -61 12 -9 106 97 172 195 51 74 59 70 213 -103 73 -83 135 -150 139 -150 4 0 44 67 91 150 l84 150 204 0 204 0 -7 173 c-23 598 -118 966 -342 1328 -31 49 -61 89 -67 89 -7 0 -29 -16 -49 -35 l-37 -35 51 -82 c74 -118 160 -305 202 -435 69 -215 116 -527 116 -768 l0 -115 -172 0 -173 0 -58 -105 c-32 -58 -62 -107 -66 -109 -4 -3 -49 44 -101 105 l-94 109 -91 0 -91 0 -21 -43 c-23 -47 -102 -157 -113 -157 -4 0 -37 23 -73 51 l-67 51 -32 -28 c-118 -99 -278 -99 -396 1 l-33 28 -60 -47 c-33 -26 -66 -49 -72 -52 -15 -5 -88 87 -118 149 l-23 47 -91 0 -92 0 -94 -110 c-51 -60 -97 -106 -102 -101 -4 4 -34 53 -65 107 l-57 99 -174 3 -174 2 6 183 c15 464 124 842 338 1173 33 50 59 93 59 95 0 3 -20 21 -44 41 l-43 37 -71 -108z" />
                <Path fill="orange" d="M620 1762 c-73 -110 -167 -306 -209 -436 -36 -110 -75 -276 -64 -276 2 0 31 29 65 65 33 36 62 64 64 63 1 -2 -9 -36 -22 -76 -14 -41 -34 -116 -45 -169 -21 -103 -42 -324 -31 -335 4 -4 48 -8 97 -10 l90 -3 45 -80 44 -79 7 39 c4 22 15 87 24 145 29 177 103 369 164 424 20 18 20 18 10 -15 -22 -75 -49 -266 -49 -345 l0 -84 149 0 148 0 6 -52 c8 -63 46 -143 68 -142 9 1 45 24 81 53 70 54 78 58 78 38 0 -22 61 -87 101 -107 50 -26 128 -26 178 0 35 17 91 72 91 88 0 4 4 13 9 21 7 11 25 2 81 -43 40 -31 78 -55 84 -53 19 5 56 92 63 145 l6 52 150 0 150 0 -6 123 c-4 72 -16 165 -31 227 -13 58 -22 107 -21 108 7 8 69 -87 97 -148 37 -81 82 -256 97 -374 6 -49 12 -91 15 -93 2 -2 23 31 47 74 l44 78 94 5 94 5 -2 80 c-4 139 -46 366 -86 461 -8 20 -14 39 -12 41 3 2 32 -26 66 -63 34 -36 64 -64 65 -63 7 9 -36 187 -69 287 -55 163 -201 449 -225 440 -5 -2 -42 -32 -84 -67 l-75 -65 35 -62 35 -62 -28 -6 c-15 -3 -89 -12 -163 -21 -74 -9 -141 -19 -149 -21 -9 -3 -3 -16 21 -47 19 -23 38 -47 42 -54 3 -7 -20 -65 -52 -130 -33 -65 -83 -176 -112 -248 -70 -172 -113 -257 -156 -305 -78 -86 -205 -111 -296 -57 -84 49 -135 134 -249 411 -29 71 -74 171 -99 223 l-47 93 48 67 c27 37 49 70 49 74 0 9 -35 15 -195 34 -71 9 -136 18 -143 20 -10 4 -6 16 13 48 14 24 25 48 25 54 0 6 -40 47 -88 91 l-88 81 -44 -67z" />
            </G>
            <G >
                <Circle cx="2000" cy="2000" r="1900" stroke="gray" strokeWidth="100" fill="none" />
            </G>
        </Svg>
    );
}
export default LoLUser;