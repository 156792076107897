import { NavigationContainer } from '@react-navigation/native';
import NewsScreen, { newsScreenName } from './src/screens/NewsScreen';
import * as WebBrowser from 'expo-web-browser';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { tabStore } from './src/states/store';
import { useEffect, useLayoutEffect } from 'react';

import { initializeApp } from 'firebase/app';
import firebase from 'firebase/compat/app';
import { Platform } from 'react-native';
import { firebaseConfig } from './config';
import LoginPhone from './src/screens/LoginPhone';
import { setUid } from './src/states/actions';

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { screens } from './src/utils/ScreenUtils';


import HomeScreen from './src/screens/HomeScreen';
import DogScreen from './src/screens/DogScreen';
import HalloweenScreen from './src/screens/HalloweenScreen';
import { listenerLiveStreamUrl } from './src/utils/LiveStream';
import MusicScreen from './src/screens/MusicScreen';
import VideoScreen from './src/screens/VideoScreen';

const Stack = createNativeStackNavigator();

try {
  //console.log(Platform.OS);
  if (Platform.OS === 'web') {
    firebase.initializeApp(firebaseConfig);
  } else {
    initializeApp(firebaseConfig);
  }
} catch (error) {
  console.log("Initializing error ", error);
}

WebBrowser.maybeCompleteAuthSession();

const Body = () => {
  const { uid } = useSelector(state => state.firebaseReducers);
  const dispatch = useDispatch();

  useLayoutEffect(()=>{
    listenerLiveStreamUrl();
  },[]);

  useEffect(() => {
    onAuthStateChanged(getAuth(), (user) => {
      // console.log("uid:", uid);
      if (user) {
        if (uid != user.uid) {
          dispatch(setUid(user.uid));
        }
      } else {
        if (uid != "") {
          dispatch(setUid(""));
        }
      }
    });
  }, [uid]);
  return (
    <NavigationContainer>
      <Stack.Navigator
        screenOptions={{
          headerShown: false
        }}
        initialRouteName={screens.news}
      >
        <Stack.Screen
          name={screens.news}
          component={NewsScreen}
          options={{ title: "News" }}
        />
        <Stack.Screen
          name={screens.home}
          component={HomeScreen}
          options={{ title: "Home" }}
        />
        <Stack.Screen
          name={screens.dog}
          component={DogScreen}
          options={{ title: "Dog" }}
        />
        <Stack.Screen
          name={screens.halloween}
          component={HalloweenScreen}
          options={{ title: "Halloween" }}
        />
        <Stack.Screen
          name={screens.login}
          component={LoginPhone}
          options={{ title: "Login" }}
        />
        <Stack.Screen
          name={screens.music}
          component={MusicScreen}
          options={{title: "Music"}}
        />
        <Stack.Screen
          name={screens.video}
          component={VideoScreen}
          options={{title: "Video"}}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default function App() {
  return (
    <Provider store={tabStore}>
      <Body />
    </Provider>
  );
}