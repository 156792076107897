import { Dimensions, Image, Text, View } from "react-native";
import { Canvas, useFrame } from "@react-three/fiber";
import { screens } from "../utils/ScreenUtils";
import { PCFSoftShadowMap, SRGBColorSpace } from "three";
import Lights from "../components/x3d/Lights";
import { Suspense, useRef } from "react";
import { Environment, OrbitControls, Svg, useGLTF } from "@react-three/drei";
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { useState } from "react";
import { useEffect } from "react";
import { Audio } from "expo-av";
import * as THREE from "three";
import { KTabIcon } from "../components/x3d/KTabItems";
import useHexagonScatter from "../hooks/useHexagonScatter";
import Terrain from "../components/ScatterHexagonMesh";

var backgroundH = require("../../assets/sky_h.jpg");
var backgroundW = require("../../assets/sky_w.jpg");
var urlMusic = "https://raw.githubusercontent.com/xxxClone/music/9fcdd00237fa3245bdfe879f19d734aa4e35e3b2/Xuan_Phat_Tai.mp3";
var srcVideo = require("../../assets/video.mp4");


const VideoScreen = ({ route, navigation }) => {
    const points = useHexagonScatter(25);
    const [dimension, setDimension] = useState(null);
    const [background, setBackground] = useState({ uri: '' });
    const [soundX, setSound] = useState(null);
    const [isPlay, setIsPlay] = useState(false);

    useEffect(() => {
        const loadSound = async () => {
            const { sound } = await Audio.Sound.createAsync(urlMusic);
            setSound(sound);
            sound.setIsLoopingAsync(true);
            // await sound.playAsync();
            //setIsPlay(true);
        };

        if (soundX == null) {
            loadSound();
        }

        return soundX ?
            () => {
                soundX.unloadAsync();
            }

            : undefined
    }, [soundX]);

    const changePlayState = async () => {
        // coding continue
        if (!isPlay) {
            if (soundX != null) {
                await soundX.playAsync();
            }
        } else {
            if (soundX != null) {
                await soundX.pauseAsync();
            }
        }
        setIsPlay(!isPlay);
    }

    useEffect(() => {
        const subscription = Dimensions.addEventListener(
            'change',
            ({ window }) => {
                if (dimension == null) {
                    setDimension(window);
                } else {
                    let pre = dimension.width >= dimension.height;
                    let now = window.width >= window.height;
                    if (pre != now) {
                        setDimension(window);
                        if (now) {
                            setBackground({ uri: backgroundH });
                        } else {
                            setBackground({ uri: backgroundW });
                        }
                    }
                }
            },
        );
        if (dimension == null) {
            let tempDimension = Dimensions.get('window');
            setDimension(tempDimension);
            let now = tempDimension.width >= tempDimension.height;
            if (now) {
                setBackground({ uri: backgroundH });
            } else {
                setBackground({ uri: backgroundW });
            }
        }
        return () => subscription?.remove();
    }, [dimension]);

    const goToHomeScreen = () => {
        navigation.navigate(screens.slide);
    }

    return (
        <View style={{ width: "100%", height: "100%", flexDirection: "column" }}>
            <Image
                style={{ width: "100%", height: "100%", resizeMode: "stretch" }}
                source={background}
            ></Image>
            <View style={{ left: 5, zIndex: 1, alignItems: "flex-start", position: 'absolute', zIndex: 3 }}>
                <View style={{ marginVertical: 5, marginHorizontal: 5, }}>
                    <Icon.Button
                        name='home'
                        backgroundColor={"blue"}
                        size={32}
                        onPress={goToHomeScreen}
                    />
                </View>
                <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
                    <Icon.Button
                        name={isPlay ? "pause" : "play"}
                        backgroundColor={"blue"}
                        onPress={changePlayState}
                        size={32}
                    />
                </View>
            </View>
            <View style={{ flex: 1, width: "100%", height: "100%", justifyContent: "flex-end", flexDirection: "column", position: "absolute", zIndex: 1 }}>
                <Canvas
                    shadows
                    gl={{
                        antialias: true,
                        toneMappingExposure: 0.5,
                        shadowMap: {
                            enabled: true,
                            type: PCFSoftShadowMap
                        },
                        outputColorSpace: SRGBColorSpace
                    }}

                    camera={{
                        position: [0, 15, 30],
                        fov: 45
                    }}
                >
                    <Lights />
                    <Suspense fallback={null}>
                        <group>
                            <group rotation-x={-Math.PI / 2} position={[0, -9, 0]} scale={2.0}>
                                <KTabIcon></KTabIcon>
                                <Terrain points={points}/>
                            </group>
                        </group>
                        <Environment preset="sunset" />
                        <OrbitControls autoRotate autoRotateSpeed={0.0} enablePan={false} />
                    </Suspense>
                </Canvas>
            </View>
        </View>
    );
}
export default VideoScreen;