import { Canvas } from "@react-three/fiber";
import { PCFSoftShadowMap, SRGBColorSpace } from "three";
import Lights from "../components/x3d/Lights";
import { Suspense, useEffect, useState } from "react";
import useHexagonScatter from "../hooks/useHexagonScatter";
import Terrain from "../components/ScatterHexagonMesh";
import { Environment, OrbitControls } from "@react-three/drei";
import { Dimensions, View } from "react-native";
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { Audio } from "expo-av";
import { bgColor } from "./NewsScreen";
import HalloweenObj, { pathMusic } from "../components/x3d/animals/HalloweenObj";
import { KTabIcon } from "../components/x3d/KTabItems";
import { Image } from "react-native";
import { screens } from "../utils/ScreenUtils";
import AssitiveTouch from "../components/AssitiveTouch";


var mainColor = "#099bee";
var backgroundH = require("../../assets/universeH.jpg");
var backgroundV = require("../../assets/universeV.jpg");

const HalloweenScreen = ({ navigation }) => {
    const points = useHexagonScatter(25);
    const [dimension, setDimension] = useState(null);
    const [isPlay, setIsPlay] = useState(false);
    const [soundX, setSound] = useState(null);
    const [background, setBackground] = useState({ uri: '' });

    useEffect(() => {
        const loadSound = async () => {
            const { sound } = await Audio.Sound.createAsync(pathMusic);
            setSound(sound);
            sound.setIsLoopingAsync(true);
            await sound.playAsync();
            setIsPlay(true);
        };

        if (soundX == null) {
            loadSound();
        }

        return soundX ?
            () => {
                soundX.unloadAsync();
            }

            : undefined
    }, [soundX]);

    useEffect(() => {
        const subscription = Dimensions.addEventListener(
            'change',
            ({ window }) => {
                if (dimension == null) {
                    setDimension(window);
                } else {
                    let pre = dimension.width >= dimension.height;
                    let now = window.width >= window.height;
                    if (pre != now) {
                        setDimension(window);
                        if (now) {
                            setBackground({ uri: backgroundH });
                        } else {
                            setBackground({ uri: backgroundV });
                        }
                    }
                }
            },
        );
        if (dimension == null) {
            let tempDimension = Dimensions.get('window');
            setDimension(tempDimension);
            let now = tempDimension.width >= tempDimension.height;
            if (now) {
                setBackground({ uri: backgroundH });
            } else {
                setBackground({ uri: backgroundV });
            }
        }
        return () => subscription?.remove();
    }, [dimension]);

    const gotoSlideScreen = () => {
        navigation.navigate(screens.news);
    }

    const changePlayState = async () => {
        // coding continue
        if (!isPlay) {
            if (soundX != null) {
                await soundX.playAsync();
            }
        } else {
            if (soundX != null) {
                await soundX.pauseAsync();
            }
        }
        setIsPlay(!isPlay);
    }

    const gotoScreen = (screenName) => {
        if (isPlay) {
            changePlayState();
        }
        navigation.navigate(screenName);
    }

    let actionOpeStyle = { flexDirection: "column", position: 'absolute', alignItems: "flex-start", left: 5, zIndex: 3 };
    if (dimension != null && dimension.width < dimension.height) {
        actionOpeStyle = { flexDirection: "row", position: 'absolute', alignItems: "center", right: 5, left: 5, bottom: 20, zIndex: 3 };
    }


    return (
        <AssitiveTouch navigation={navigation} screen={screens.halloween}>
            <View style={{ width: "100%", height: "100%", alignItems: "center", justifyContent: "center", backgroundColor: bgColor, flexDirection: "column" }}>
                <Image
                    style={{ width: "100%", height: "100%", resizeMode: "stretch" }}
                    source={background}
                ></Image>
                
                <View style={actionOpeStyle} >
                    <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
                        <Icon.Button
                            name="newspaper"
                            backgroundColor={mainColor}
                            onPress={gotoSlideScreen}
                            size={32}
                        />
                    </View>
                    <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
                        <Icon.Button
                            name="human-male-female-child"
                            backgroundColor={mainColor}
                            onPress={() => gotoScreen("Home")}
                            size={32}
                        />
                    </View>
                    <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
                        <Icon.Button
                            name="dog"
                            backgroundColor={mainColor}
                            onPress={() => gotoScreen("Dog")}
                            size={32}
                        />
                    </View>
                    <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
                        <Icon.Button
                            name={isPlay ? "pause" : "play"}
                            backgroundColor={mainColor}
                            onPress={changePlayState}
                            size={32}
                        />
                    </View>
                </View>
                <View style={{ flex: 1, width: "100%", height: "100%", justifyContent: "flex-end", flexDirection: "column", position: "absolute", zIndex: 1 }}>
                    <Canvas
                        shadows

                        gl={{
                            antialias: true,
                            toneMappingExposure: 0.5,
                            shadowMap: {
                                enabled: true,
                                type: PCFSoftShadowMap
                            },
                            outputColorSpace: SRGBColorSpace
                        }}

                        camera={{
                            position: [8, 8, 15],
                            fov: 45
                        }}

                    >
                        <Lights />
                        <Suspense fallback={null}>
                            <group rotation-x={-Math.PI / 2} position={[0,-3,0]}>
                                <Terrain points={points} />
                                <KTabIcon></KTabIcon>
                                <HalloweenObj></HalloweenObj>
                            </group>
                            <Environment preset="sunset" />
                            <OrbitControls autoRotate autoRotateSpeed={0.6} enablePan={false} />
                        </Suspense>
                    </Canvas>
                </View>
            </View>
        </AssitiveTouch>
    );
}
export default HalloweenScreen;