import { useEffect, useState } from "react";
import { useRef } from "react";
import { Platform, Pressable, StyleSheet, TextInput } from "react-native";
import { View } from "react-native";
import { FirebaseRecaptchaBanner, FirebaseRecaptchaVerifierModal } from "expo-firebase-recaptcha";
import CountryPicker from 'react-native-country-picker-modal';
import { firebaseConfig } from "../../config";
import { Text } from "react-native";
import { getAuth, PhoneAuthProvider, signInWithCredential } from "firebase/auth";
import { getApp} from "firebase/app";
import { useSelector } from "react-redux";
import { getLocalStorage } from "../utils/LocalStorage";

const LoginPhone = ({navigation}) => {

    const recaptchaVerifier = useRef(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [suffixPhoneNumber, setSuffixPhoneNumber] = useState('');
    const [verificationId, setVerificationID] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [info, setInfo] = useState('');

    const [countryCode, setCountryCode] = useState('+84');
    const [country, setCountry] = useState({ 'cca2': 'VN', callingCode: ['84'] });

    const {uid} = useSelector(state => state.firebaseReducers);
    useEffect(()=>{
        // if(uid != ''){
        // }
    },[uid]);

    function resetComponent() {
        setPhoneNumber('');
        setVerificationID('');
        setVerificationCode('');
    }
    const sendVerificationCode = async () => {
        if (suffixPhoneNumber == "") {
            return;
        }
        try {
            let auth = getAuth(getApp());
            auth.languageCode = 'vi';
            if (country['cca2'] != 'VN') {
                auth.languageCode = 'vi';
            }
            let phoneProvider = new PhoneAuthProvider(auth);
            let verificationId = await phoneProvider.verifyPhoneNumber(
                phoneNumber,
                recaptchaVerifier.current
            );
            setVerificationID(verificationId);
        } catch (error) {
            // setInfo(`Error : ${error.message}`);
            setInfo("Error");
            resetComponent();
        }
    };

    const verifyVerificationCode = async () => {
        if (verificationCode == '') {
            return;
        }
        try {
            const credential = PhoneAuthProvider.credential(verificationId, verificationCode);
            await signInWithCredential(getAuth(), credential);
            let lastScreen = await getLocalStorage("screen");
            navigation.navigate(lastScreen);
        } catch (error) {
            // setInfo(`Error : ${error.message}`);
            setInfo("Error");
            resetComponent();
        }
    };
    const updateCountry = (_country) => {
        setCountryCode("+" + _country['callingCode'][0]);
        setCountry(_country);
    }
    const updatePhoneNumber = (phone) => {
        setSuffixPhoneNumber(phone);
        if (phone == '') {
            return;
        }
        let strPhoneNumber = countryCode + phone;

        if (phone.startsWith('0')) {
            strPhoneNumber = countryCode + phone.substring(1);
        }
        //console.log(strPhoneNumber);
        setPhoneNumber(strPhoneNumber);
    }
    return (
        <View style={TabStyle.container}>
            <FirebaseRecaptchaVerifierModal
                ref={recaptchaVerifier}
                firebaseConfig={firebaseConfig}
            />
            {info ?
                <>
                    <Text style={[TabStyle.text, { color: 'red' }]}>{info}</Text>
                </>
                : null
            }
            {!verificationId ?
                <>
                    <Text style={TabStyle.title}>Enter the phone number</Text>
                    <CountryPicker
                        countryCode={country['cca2']}
                        withFilter
                        withFlag
                        withCountryNameButton
                        withAlphaFilter
                        withCallingCode
                        withEmoji
                        withFlagButton={true}
                        onSelect={updateCountry}
                    ></CountryPicker>
                    <TextInput
                        placeholder='.........'
                        autoFocus
                        returnKeyType='done'
                        autoCompleteType='tel'
                        keyboardType={Platform.OS === 'ios' ? 'number-pad' : 'numeric'}
                        textContentType='telephoneNumber'
                        maxLength={20}
                        value={suffixPhoneNumber}
                        onChangeText={(phone) => updatePhoneNumber(phone)}
                        style={TabStyle.text_input}
                    />

                    <Pressable
                        style={[TabStyle.button, { width: '50%', marginTop: 20 }]}
                        onPress={sendVerificationCode}
                    >
                        <Text style={TabStyle.text}>
                            Send Code
                        </Text>
                    </Pressable>
                </>
                : null
            }
            {verificationId ?
                <>
                    <Text style={TabStyle.title}>Enter the verification code</Text>

                    <TextInput
                        editable={!!verificationId}
                        placeholder="......"
                        returnKeyType="done"
                        onChangeText={setVerificationCode}
                        style={TabStyle.text_input}
                        maxLength={6}
                    />
                    <Pressable
                        style={[TabStyle.button, { width: '50%', marginTop: 20 }]}
                        onPress={verifyVerificationCode}
                        disabled={!verificationCode}
                    >
                        <Text style={TabStyle.text}>
                            Confirm Code
                        </Text>
                    </Pressable>
                </>
                : null
            }
            <FirebaseRecaptchaBanner />
        </View>
    );
};

const TabStyle = StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
    },
    button: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 12,
        paddingHorizontal: 32,
        borderRadius: 4,
        elevation: 3,
        backgroundColor: 'blue',
    },
    text: {
        fontSize: 16,
        lineHeight: 21,
        fontWeight: 'bold',
        letterSpacing: 0.25,
        color: 'white',
    },
    title: {
        fontSize: 30,
        margin: 10,
    },
    slide_image: {
        width: '100%',
        height: '100%',
    },
    slide_caption:{
        alignItems: 'left',
        elevation: 3,
        backgroundColor: 'blue',
        opacity: 0.85,
    },
    text_input:{
        height: 50,
        textAlign:'center',
        fontSize: 40,
        fontWeight: 'bold',
        padding: 0,
        margin:0,
        color: '#0000ff',
        borderColor:'#0000ff',
        borderWidth: 2,
    }
});

export default LoginPhone;