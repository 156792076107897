import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { getApp } from 'firebase/app';
import { BACKGROUNDSLIDES_STORAGE, BACKGROUNDSLIDE_FIRESTORE, DEFAULT_SUFFIX_IMG, GROUPINFOS_FIRESTORE, SHORTCUT_FIRESTORE, THRESH_NAVIGATION } from "./Constant";

export const generateRandomString = (length = 32) => {
    const char = 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890';
    const random = Array.from(
        { length: length },
        () => char[Math.floor(Math.random() * char.length)]
    );
    return random.join("");
}

export function random(bound) {
    return Math.floor(Math.random() * (bound + 0.5));
}
export function getRandomFuture(bound) {
    let min_value = 0;
    let max_value = bound - 1;
    while (min_value < max_value) {
        let mid_value =Math.floor((min_value + max_value) / 2);
        mid_value = Math.floor((mid_value + max_value + 1) / 2);
        if (random(3) == 0) {
            max_value = mid_value - 1;
        } else {
            min_value = mid_value;
        }
    }
    return min_value;
}

export const getUrl4Slide = async (slideId) => {
    let storage = getStorage(getApp());
    let slideRef = ref(storage, BACKGROUNDSLIDES_STORAGE + slideId + DEFAULT_SUFFIX_IMG);
    let downloadUrl = await getDownloadURL(slideRef);
    return downloadUrl;
}

export const getUrlFromStorage = async(storageFile)=>{
    let storage = getStorage(getApp());
    let slideRef = ref(storage, storageFile);
    let downloadUrl = await getDownloadURL(slideRef);
    return downloadUrl;
}

export const getSlideData = async (slideId) => {
    let db = getFirestore(getApp());
    let slideDocSnapshot = await getDoc(doc(db, BACKGROUNDSLIDE_FIRESTORE, String(slideId)));
    if (slideDocSnapshot.exists()) {
        return slideDocSnapshot.data();
    }
    return {};
}
export const firestoreRef= async(collectionId, docId)=>{
    // console.log("collectionId: ", collectionId, "docId: ", docId);
    let db = getFirestore(getApp());
    let docRef = doc(db, collectionId, docId);
    return docRef;
}

export const updateRoomVoice = async (room, offer) =>{
    let db = getFirestore(getApp());
    await updateDoc(doc(db,"rooms", room), offer);
    let roomVoiceSnapshot = await getDoc(doc(db,"rooms", room));
    return roomVoiceSnapshot;
}
export const getRoomVoice = async (room) =>{
    let db = getFirestore(getApp());
    let roomVoiceSnapshot = await getDoc(doc(db,"rooms", room));
    return roomVoiceSnapshot;
}

export const genMaxSlide4Active = async (activeGroup) => {
    let db = getFirestore(getApp());
    let slideId = "-1";
    let groupInfoSnapshot = await getDoc(doc(db, GROUPINFOS_FIRESTORE, activeGroup));
    if (!groupInfoSnapshot.exists()) {
        return slideId;
    }
    let groupInfo = groupInfoSnapshot.data();
    slideId = parseInt(groupInfo.maxSlideId);
    return slideId;
}

export const genSlide4Active = async (activeGroup) => {
    let db = getFirestore(getApp());
    let slideId = "-1";
    let groupInfoSnapshot = await getDoc(doc(db, GROUPINFOS_FIRESTORE, activeGroup));
    if (!groupInfoSnapshot.exists()) {
        return slideId;
    }
    let groupInfo = groupInfoSnapshot.data();
    let capacity = groupInfo.capacity?groupInfo.capacity: '0';
    if(capacity == '0'){
        return slideId;
    }
    let removeSlide = groupInfo.removeSlide?groupInfo.removeSlide: '0';
    let numberSlide = groupInfo.numberSlide?groupInfo.numberSlide: '1';
    let slideGroupIndex = parseInt(removeSlide) + random(Math.min(parseInt(numberSlide) - parseInt(removeSlide))) + 1;
    let shortcutSnapshot = await getDoc(doc(db, SHORTCUT_FIRESTORE, activeGroup + '_' + slideGroupIndex));
    if (!shortcutSnapshot.exists()) {
        return slideId;
    }
    let shortcut = shortcutSnapshot.data();
    // console.log(shortcut.slideId);
    return shortcut.slideId;
}

export const sleep = (ms)=>{
    return new Promise(resolve => setTimeout(resolve, ms));
}