import { Svg } from '@react-three/drei';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import { Suspense, useLayoutEffect } from 'react';
import { useRef, useState } from 'react';
import { TextureLoader } from 'three';
import { MTLLoader, OBJLoader } from 'three-stdlib';

import * as THREE from 'three';
// extend({extrudeBufferGeometry});

var svgPath = require("../../../assets/logo.svg");
var childPath = require("../../../assets/01/obj.obj");
var fatherPath = require("../../../assets/02/obj.obj");
var motherPath = require("../../../assets/03/obj.obj");

export function XgameSvg() {
  return (
    <Svg src={svgPath} scale={0.02} position={[-1.5, 0, 6.5]} rotation-x={Math.PI / 2}></Svg>
  );
}

// https://github.com/bhushan6/lego-builder/blob/main/src/App.jsx
const shiftValue = -0.75;
const shiftValueX = -1;
export function CandlesObj() {
  // const material = useLoader(MTLLoader, mtlPath);
  // const [base]= useLoader(TextureLoader,[facePath]);
  const objChild = useLoader(OBJLoader, childPath, (loader) => {
    // material.preload();
    // loader.setMaterials(material);
  });
  const objMother = useLoader(OBJLoader, fatherPath);
  const objFather = useLoader(OBJLoader, motherPath);

  useLayoutEffect(() => {
    objChild.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.material.color = new THREE.Color("red");
      }
    });
    objMother.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.material.color = new THREE.Color("blue");
      }
    });
    objFather.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.material.color = new THREE.Color("yellow");
      }
    });
  }, [objChild, objFather, objMother]);

  return (
    <group>
      <mesh scale={0.165} position={[0.5, 0, 3]}>
        <Suspense fallback={null}>
          <primitive object={objChild}></primitive>
        </Suspense>
      </mesh>
      <mesh scale={0.165} position={[-0.45, -0.5, 3]}>
        <Suspense fallback={null}>
          <primitive object={objFather}></primitive>
        </Suspense>
      </mesh>
      <mesh scale={0.165} position={[0.5, -0.25, 3]}>
        <Suspense fallback={null}>
          <primitive object={objMother}></primitive>
        </Suspense>
      </mesh>
    </group>

  );
}

export function Hexagon(props) {
  const mesh = useRef();
  const { color, active } = props;
  // https://docs.pmnd.rs/react-three-fiber/api/hooks
  useFrame((state, delta, xrFrame) => {
    if (active) {
      mesh.current.rotation.z += delta;
    } else {
      mesh.current.rotation.z -= delta;
    }
  });

  return (
    <mesh
      {...props}
      ref={mesh}
      scale={0.65}
    >
      <circleGeometry args={[1.0, 6, 0, Math.PI * 2]} />
      <meshPhongMaterial
        color={color}
      />
    </mesh>
  );
}

export function KTabIcon(props) {
  const mesh = useRef();
  const [active, setActive] = useState(true);
  useFrame((state, delta, xrFrame) => {
    if (active) {
      mesh.current.rotation.z += delta / 5;
    } else {
      mesh.current.rotation.z -= delta / 5;
    }
  });
  return (
    <group
      {...props}
      ref={mesh}
      onClick={(event) => setActive(!active)}
      scale={1.5}
    >
      <Hexagon position={[Math.cos(1 * Math.PI / 6) * 1.25, Math.sin(1 * Math.PI / 6) * 1.25, 1.65]} color={"red"} active={active} />
      <Hexagon position={[Math.cos(5 * Math.PI / 6) * 1.25, Math.sin(5 * Math.PI / 6) * 1.25, 1.65]} color={"blue"} active={active} />
      <Hexagon position={[Math.cos(9 * Math.PI / 6) * 1.25, Math.sin(9 * Math.PI / 6) * 1.25, 1.65]} color={"yellow"} active={active} />
    </group>
  );
}

export default function KTabItems() {
  return (
    <Canvas>
      <ambientLight></ambientLight>
      <pointLight position={[10, 10, 10]}></pointLight>
      <Svg src={svgPath} scale={0.01} color={"orange"} position={[-1, 1, 0]}></Svg>
    </Canvas>
  );
}