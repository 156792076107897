import AsyncStorage from "@react-native-async-storage/async-storage";

export const setLocalStorage = async(key, value)=>{
    try{
        await AsyncStorage.setItem(key, value);
    }catch(error){
        console.error(error);
    }
}
export const getLocalStorage = async(key)=>{
    try{
        let value = await AsyncStorage.getItem(key);
        return value;
    }catch(error){
        console.error(error);
        return "";
    }
}