import { useLoader } from "@react-three/fiber";
import { useLayoutEffect } from "react";
import { Suspense } from "react";
import { MTLLoader, OBJLoader } from "three-stdlib";
import * as THREE from "three";

var pathCatObj = require("../../../../assets/animals/cat.obj");
var pathCatMtl = require("../../../../assets/animals/cat.mtl");
export var pathMusic = require("../../../../assets/animals/Demon.mp3");

const HalloweenObj = ()=>{
    const material = useLoader(MTLLoader, pathCatMtl);
    const halloweenObj = useLoader(OBJLoader, pathCatObj, (loader)=>{
        material.preload();
        loader.setMaterials(material);
    });
    useLayoutEffect(() => {
        halloweenObj.traverse((child) => {
            if (child instanceof THREE.Mesh) {
            }
        });
    }, [halloweenObj]);
    // const halloweenFbx = useLoader(FBXLoader, pathHalloweenFbx);
    
    return(
        <group>
            <mesh scale={0.01} position={[0, 0, 2.75]} rotation-x={Math.PI / 2}>
                <Suspense fallback={null}>
                    <primitive object={halloweenObj}></primitive>
                    {/* <primitive object={halloweenFbx}></primitive> */}
                </Suspense>
            </mesh>
        </group>
    );
}
export default HalloweenObj;