import { SET_UID } from "./actions";
const initGlobalState = {
    uid: '',
};
function firebaseReducers(state = initGlobalState, action) {
    switch (action.type) {
        case SET_UID:
            return { ...state, uid: action.payload };
        default:
            return state;
    }
};
export default firebaseReducers;