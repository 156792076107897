import { Canvas } from "@react-three/fiber";
import { PCFSoftShadowMap, SRGBColorSpace } from "three";
import Lights from "../components/x3d/Lights";
import { Suspense, useEffect, useState } from "react";
import useHexagonScatter from "../hooks/useHexagonScatter";
import Terrain from "../components/ScatterHexagonMesh";
import { Environment, OrbitControls } from "@react-three/drei";
import { GokuObj, KTabIcon, XgameSvg } from "../components/x3d/KTabItems";
import { Dimensions, View } from "react-native";
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { Audio } from "expo-av";
import DogObj from "../components/x3d/animals/DogObj";

var mainColor = "#099bee";
import { pathMusic } from "../components/x3d/animals/DogObj";
import { bgColor } from "./NewsScreen";
import { screens } from "../utils/ScreenUtils";
import AssitiveTouch from "../components/AssitiveTouch";

const DogScreen = ({ navigation }) => {
    const points = useHexagonScatter(25);
    const [dimension, setDimension] = useState(null);
    const [isPlay, setIsPlay] = useState(false);
    const [soundX, setSound] = useState(null);

    useEffect(() => {
        const loadSound = async () => {
            const { sound } = await Audio.Sound.createAsync(pathMusic);
            setSound(sound);
            sound.setIsLoopingAsync(true);
            await sound.playAsync();
            setIsPlay(true);
        };

        if (soundX == null) {
            loadSound();
        }

        return soundX ?
            () => {
                soundX.unloadAsync();
            }

            : undefined
    }, [soundX]);

    useEffect(() => {
        const subscription = Dimensions.addEventListener(
            'change',
            ({ window }) => {
                if (dimension == null) {
                    setDimension(window);
                } else {
                    let pre = dimension.width >= dimension.height;
                    let now = window.width >= window.height;
                    if (pre != now) {
                        setDimension(window);
                    }
                }
            },
        );
        if (dimension == null) {
            setDimension(Dimensions.get('window'));
        }
        return () => subscription?.remove();
    }, [dimension]);

    const gotoSlideScreen = () => {
        navigation.navigate(screens.news);
    }

    const changePlayState = async () => {
        // coding continue
        if (!isPlay) {
            if (soundX != null) {
                await soundX.playAsync();
            }
        } else {
            if (soundX != null) {
                await soundX.pauseAsync();
            }
        }
        setIsPlay(!isPlay);
    }

    const gotoScreen = (screenName) => {
        if (isPlay) {
            changePlayState();
        }
        navigation.navigate(screenName);
    }

    let actionOpeStyle = { flexDirection: "column", position: 'absolute', alignItems: "flex-start", left: 5, zIndex: 2 };
    if (dimension != null && dimension.width < dimension.height) {
        actionOpeStyle = { flexDirection: "row", position: 'absolute', alignItems: "center", right: 5, left: 5, bottom: 20, zIndex: 1 };
    }

    return (
        <AssitiveTouch navigation={navigation} screen={screens.dog}>
            <View style={{ width: "100%", height: "100%", alignItems: "center", justifyContent: "center", backgroundColor: bgColor }}>
                <View style={actionOpeStyle} >
                    <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
                        <Icon.Button
                            name="newspaper"
                            backgroundColor={mainColor}
                            onPress={gotoSlideScreen}
                            size={32}
                        />
                    </View>
                    <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
                        <Icon.Button
                            name="human-male-female-child"
                            backgroundColor={mainColor}
                            onPress={() => gotoScreen("Home")}
                            size={32}
                        />
                    </View>
                    <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
                        <Icon.Button
                            name="halloween"
                            backgroundColor={mainColor}
                            onPress={() => gotoScreen("Halloween")}
                            size={32}
                        />
                    </View>
                    <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
                        <Icon.Button
                            name={isPlay ? "pause" : "play"}
                            backgroundColor={mainColor}
                            onPress={changePlayState}
                            size={32}
                        />
                    </View>
                </View>
                <Canvas
                    shadows

                    gl={{
                        antialias: true,
                        toneMappingExposure: 0.5,
                        shadowMap: {
                            enabled: true,
                            type: PCFSoftShadowMap
                        },
                        outputColorSpace: SRGBColorSpace
                    }}

                    camera={{
                        position: [5, 15, 10],
                        fov: 45
                    }}

                >
                    <Lights />
                    <Suspense fallback={null}>
                        <group rotation-x={-Math.PI / 2} position={[0,-3,0]}>
                            <Terrain points={points} />
                            <KTabIcon></KTabIcon>
                            <DogObj></DogObj>
                        </group>
                        <Environment preset="sunset" />
                        <OrbitControls autoRotate autoRotateSpeed={0.6} enablePan={false} />
                    </Suspense>
                </Canvas>
            </View>
        </AssitiveTouch>

    );
}
export default DogScreen;