import { getDatabase, ref, onValue, child, off } from "firebase/database";
var liveStream = "";
var firstLiveStreamUrl = true;
var liveStreamRef = null;

export const getLiveStreamUrl = () => {
    return liveStream;
}
export const listenerLiveStreamUrl = () => {
    if (firstLiveStreamUrl) {
        firstLiveStreamUrl = false;
        liveStreamRef = child(ref(getDatabase()), "livestream");
        onValue(liveStreamRef, (snapshot) => {
            if (snapshot.exists()) {
                liveStream = snapshot.val();
            }else{
                liveStream = "";
            }
        });
    }
}