import { useLoader } from "@react-three/fiber";
import { Suspense } from "react";
import { MTLLoader, OBJLoader } from "three-stdlib";

var pathDogObj = require("../../../../assets/animals/Dog.obj");
var pathDogMtl = require("../../../../assets/animals/Dog.mtl");
export var pathMusic = require("../../../../assets/animals/Dog.mp3");

const DogObj = ()=>{
    const material = useLoader(MTLLoader, pathDogMtl);
    
    const obj = useLoader(OBJLoader, pathDogObj, (loader) => {
        material.preload();
        loader.setMaterials(material);
    });

    return(
        <group>
            <mesh scale={1.37} position={[0, -0.7, 3]} rotation-x={Math.PI / 3}>
                <Suspense fallback={null}>
                    <primitive object={obj}></primitive>
                </Suspense>
            </mesh>
        </group>
    );
}
export default DogObj;